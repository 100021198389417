<template>
  <div>
    <div
      class="scroll-line"
      :style="'width:' + progress.scroll_line + '%'"
    ></div>
    <div class="scroll-circle">
      <div class="circle-left">
        <div
          class="left"
          :style="'transform: rotate(' + progress.circle_left + 'deg);'"
        ></div>
      </div>
      <div class="circle-right">
        <div
          class="right"
          :style="'transform: rotate(' + progress.circle_right + 'deg);'"
        ></div>
      </div>
      <span class="progress-value">{{ progress.scroll_line }}%</span>
    </div>
    <div class="menu-toggle">
      <i class="fa fa-reorder" @click="drawer = true"></i>
      <el-drawer v-model="drawer" :with-header="false" direction="ltr">
        <el-menu
          :default-active="this.$route.path"
          mode="vertical"
          router
          :ellipsis="false"
        >
          <div class="head-portrait" style="margin: 0 auto">
            <img :src="logoUrl" width="60px" height="60px" />
          </div>
          <div v-for="(nav, index) in navs" :key="index">
            <el-menu-item
              v-if="nav.children == null || nav.children.length == 0"
              :index="nav.link"
            >
              <i :class="nav.icon"></i>{{ nav.name }}
            </el-menu-item>
            <el-sub-menu v-else :index="nav.link">
              <template #title>
                <i :class="nav.icon"></i>{{ nav.name }}
              </template>
              <template v-for="(children, index) in nav.children" :key="index">
                <el-menu-item :index="children.link">
                  <i :class="children.icon"></i>{{ children.name }}
                </el-menu-item>
              </template>
            </el-sub-menu>
          </div>
        </el-menu>
      </el-drawer>
    </div>
    <nav :class="navBarClass">
      <div class="container">
        <el-menu
          :default-active="this.$route.path"
          mode="horizontal"
          router
          :ellipsis="false"
        >
          <div class="head-portrait">
            <img :src="logoUrl" width="60px" height="60px" />
          </div>
          <div v-for="(nav, index) in navs" :key="index">
            <el-menu-item
              v-if="nav.children == null || nav.children.length == 0"
              :index="nav.link"
            >
              <i :class="nav.icon"></i>{{ nav.name }}
            </el-menu-item>
            <el-sub-menu v-else :index="nav.link">
              <template #title>
                <i :class="nav.icon"></i>{{ nav.name }}
              </template>
              <template v-for="(children, index) in nav.children" :key="index">
                <el-menu-item :index="children.link">
                  <i :class="children.icon"></i>{{ children.name }}
                </el-menu-item>
              </template>
            </el-sub-menu>
          </div>
        </el-menu>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "navBar",
  computed: {
    ...mapState({
      logoUrl: (state) => state.settings.configs.logoUrl,
    }),
  },
  data: () => ({
    progress: {
      scroll_line: "0",
      circle_left: "-180",
      circle_right: "-180",
    },
    navBarClass: "navBar",
    navs: [],
    drawer: false,
  }),
  mounted() {
    window.addEventListener("scroll", this.windowScroll);
  },
  methods: {
    windowScroll() {
      //计算内容百分比
      let totalHeight =
        document.body.scrollHeight || document.documentElement.scrollHeight;
      let clientHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let validHeight = totalHeight - clientHeight;
      let percent = (scrollTop / validHeight) * 100;
      if (percent == 0) {
        this.navBarClass = "navBar";
      } else {
        this.navBarClass = "navBar show";
      }
      //设置顶部滚动条
      this.progress.scroll_line = percent.toFixed(0);
      //设置圆弧滚动条
      const circle = 360 * (percent / 100);
      if (circle < 180) {
        this.progress.circle_right = -180 + circle;
        this.progress.circle_left = -180;
      } else {
        this.progress.circle_right = 0;
        this.progress.circle_left = -180 + (circle - 180);
      }
    },
  },
  created() {
    const menus = Object.assign([], this.$store.state.settings.menus)
    menus.forEach((item) => {
      if (item.isShow == 1) {
        this.navs.push(item)
      }
    })
  }
};
</script>
<style>
/* 进度条 */
.scroll-line {
  position: fixed;
  top: 0;
  width: 0;
  height: 0.1875rem;
  background: var(--primary-color);
  box-shadow: 0 1px 5px var(--primary-color);
  transition: all 0.5s;
  z-index: 2000;
}
.scroll-circle {
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--panel-color);
  z-index: 10001;
}
.scroll-circle .circle-left,
.scroll-circle .circle-right {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  z-index: 10002;
}
.scroll-circle .circle-left .left,
.scroll-circle .circle-right .right {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  background: var(--primary-color);
  width: 100%;
  height: 100%;
  z-index: 10003;
  transform: rotate(-180deg);
}
.scroll-circle .circle-left,
.scroll-circle .circle-left .left {
  clip: rect(auto, 18px, auto, auto);
}
.scroll-circle .circle-right,
.scroll-circle .circle-right .right {
  clip: rect(0, auto, auto, 18px);
}
.scroll-circle .progress-value {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  border-radius: 50%;
  background: var(--background-color);
  color: var(--text-color);
  text-align: center;
  z-index: 10004;
}
/* 导航栏 */
.menu-toggle {
  position: fixed;
  top: 1rem;
  left: 1rem;
  display: none;
  z-index: 10001;
  cursor: pointer;
}
.head-portrait {
  width: 60px;
  height: 60px;
  padding: 0 10px;
}
.head-portrait img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid var(--border-color);
}
nav.navBar {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0.625rem 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0;
  z-index: 1500;
  opacity: 0;
  transition: all 0.4s;
  font-family: "BiaoSong", sans-serif;
}
nav.navBar:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  transition: all 500ms;
}
nav.navBar .container ul div li,
nav.navBar .container ul div li .el-sub-menu__title {
  color: #fff;
}
nav.navBar.show .container ul div li,
nav.navBar.show .container ul div li .el-sub-menu__title {
  color: var(--text-color);
}
nav.navBar.show {
  opacity: 1;
  background: var(--background-color);
  box-shadow: 0 1px 30px -6px var(--shadow-color);
  transition: all 500ms;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    width: 750px;
  }
  .menu-toggle {
    color: var(--text-color);
    display: inline-block;
  }
  nav.navBar {
    opacity: 1;
    height: 50px;
  }
  .container ul {
    opacity: 0;
  }
}
.el-menu {
  --el-menu-active-color: var(--primary-color) !important;
  --el-menu-text-color: var(--text-color) !important;
  --el-menu-hover-text-color: var(--primary-color) !important;
  --el-menu-bg-color: none !important;
  --el-menu-hover-bg-color: none !important;
  --el-menu-item-font-size: 16px !important;
  border: none !important;
}
.el-menu-item {
  cursor: url("../../assets/mouse/link.png"), pointer !important;
  font-weight: bold;
  justify-content: center;
}
.el-sub-menu__title {
  font-weight: bold;
}
.el-sub-menu__title:hover {
  color: var(--primary-color);
}
.el-popper .el-menu--horizontal,
.el-popper.is-light {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  border: none !important;
}
.el-sub-menu .el-sub-menu__icon-arrow {
  position: unset !important;
  margin-top: 0 !important;
}
.el-menu--popup {
  min-width: 100px !important;
}
.el-menu--horizontal > div .el-menu-item.is-active,
.el-menu--horizontal > div .el-sub-menu.is-active .el-sub-menu__title {
  border-bottom: 2px solid var(--el-menu-active-color);
  color: var(--el-menu-active-color) !important;
}
.el-menu--horizontal {
  border-radius: 20px;
}
.el-popper::after {
  content: " ";
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
}
.el-overlay-dialog {
  background-color: darkgray;
}
</style>
