<template>
  <start />
  <navBar />
  <el-container>
    <router-view />
    <el-footer>
      <div>
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=45082102000370" target="_blank">
          <img style="height: 16px" src="@/assets/image/beian.png">
          桂公网安备45082102000370号
        </a>
        <br/>
        <a href="https://beian.miit.gov.cn/" target="_blank">
          桂ICP备2021000160号-3
        </a>
        <br />
        {{ powerBy }}
        <i class="iconfont icon-sakura-flower rotating"></i>&nbsp;
      </div>
      <div class="github-badge">
        <span class="badge-subject">今日访问</span>
        <span class="badge-value bg-blueviolet">{{ toDayVisit }}</span>
      </div>
      <div class="github-badge">
        <span class="badge-subject">总访客量</span>
        <span class="badge-value bg-blueviolet">{{ countVisit }}</span>
      </div>
      <div class="github-badge">
        <span class="badge-subject">运行</span>
        <span class="badge-value bg-blueviolet">{{ runtime }}天</span>
      </div>
    </el-footer>
  </el-container>
  <CanvasClick />
  <options />
</template>

<script>
import navBar from "@/components/common/navBar";
import CanvasClick from "@/components/common/CanvasClick";
import options from "@/components/common/options";
import Start from "@/components/common/start";
import { mapState } from "vuex";
export default {
  name: "index",
  computed: {
    ...mapState({
      powerBy: (state) => state.settings.configs.powerBy,
      toDayVisit: (state) => state.settings.configs.toDayVisit,
      countVisit: (state) => state.settings.configs.countVisit,
      runtime: (state) => state.settings.configs.runtime,
    }),
  },
  components: {
    Start,
    CanvasClick,
    options,
    navBar,
  },
};
</script>

<style scoped>
.el-footer {
  clear: both;
  height: 200px;
  margin-top: 1rem;
  text-align: center;
  padding: 1rem 0;
  background: rgba(0, 0, 0, 0);
  color: var(--text-color);
  text-align: center;
  letter-spacing: 2px;
  font-family: "Merriweather Sans", Helvetica, Tahoma, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei", "WenQuanYi Micro Hei", sans-serif;
}
.el-footer a {
  text-decoration: none;
  color: var(--text-color);
}
.rotating {
  display: inline-block;
  -webkit-animation: rotate 8s linear infinite;
  -moz-animation: rotate 8s linear infinite;
  -ms-animation: rotate 8s linear infinite;
  -o-animation: rotate 8s linear infinite;
  animation: rotate 8s linear infinite;
}

.github-badge {
  display: inline-block;
  border-radius: 4px;
  text-shadow: none;
  font-size: 12px;
  color: #fff;
  line-height: 15px;
  background-color: #abbac3;
  margin-bottom: 5px;
  margin-right: 5px;
}
.github-badge .badge-subject {
  display: inline-block;
  background-color: #4d4d4d;
  padding: 4px 4px 4px 6px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.github-badge .bg-orange {
  background-color: orange !important;
}
.github-badge .bg-blueviolet {
  background-color: #8833d7 !important;
}
.github-badge .badge-value {
  display: inline-block;
  padding: 4px 6px 4px 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
  font-weight: 700;
}
</style>
