<template>
  <div class="options animated fadeInRight" @click="showOptions">
    <i class="fa fa-cog fa-2x faa-spin animated"></i>
  </div>
  <div :class="options.up" title="回到顶部" @click="backtop">
    <i class="fa fa-arrow-up"></i>
  </div>
  <div :class="options.change_theme" title="切换模式" @click="change_theme">
    <i class="fa fa-adjust"></i>
  </div>
  <div :class="options.music" title="播放音乐">
    <i class="fa fa-music"></i>
  </div>
</template>
<script>
export default {
  name: "options",
  data: () => ({
    options: {
      isShow: false,
      up: "option-item up",
      change_theme: "option-item change-theme",
      music: "option-item music",
    },
  }),
  methods: {
    showOptions() {
      if (this.options.isShow) {
        this.options.isShow = false;
        this.options.up = "option-item up";
        this.options.change_theme = "option-item change-theme";
        this.options.music = "option-item music";
      } else {
        this.options.isShow = true;
        this.options.up = "option-item up show";
        this.options.change_theme = "option-item change-theme show";
        this.options.music = "option-item music show";
      }
    },
    backtop() {
      cancelAnimationFrame(this.timer);
      const self = this;
      self.timer = requestAnimationFrame(function fn() {
        const oTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop =
            oTop - 50;
          self.timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(self.timer);
        }
      });
    },
    change_theme() {
      let theme = document.querySelector("body").getAttribute("theme");
      switch (theme) {
        case "dark": {
          document.querySelector("body").setAttribute("theme", "light");
          this.$store.state.theme = "light";
          break;
        }
        case "light": {
          document.querySelector("body").setAttribute("theme", "dark");
          this.$store.state.theme = "dark";
          break;
        }
      }
    },
  },
};
</script>
<style scoped>
.options {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: var(--primary-color);
  cursor: url("../../assets/mouse/link.png"), pointer;
  z-index: 1999;
  transition: all 0.3s;
}
.options:hover {
  opacity: 0.9;
  box-shadow: 0 1px 20px -4px var(--orange-color);
}
.options i {
  position: absolute;
  top: 17px;
  left: 18px;
  color: #fff;
  pointer-events: none;
}
.option-item {
  position: fixed;
  bottom: 22px;
  right: 22px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  box-shadow: 0 1px 20px -10px var(--shadow-color);
  cursor: url("../../assets/mouse/link.png"), pointer;
  transition: all 0.3s;
  opacity: 0;
  z-index: 999;
}
.option-item.show:hover {
  transform: scale(1.1);
  box-shadow: 0 1px 7px 1px var(--shadow-hover-color);
}
.option-item i {
  position: absolute;
  top: 17px;
  left: 18px;
  color: #fff;
  pointer-events: none;
}
.option-item.up {
  background: var(--theme-blue);
}
.option-item.up.show {
  bottom: 110px;
  opacity: 1;
}
.option-item.change-theme {
  background: var(--theme-green);
}
.option-item.change-theme.show {
  right: 86px;
  bottom: 86px;
  opacity: 1;
}
.option-item.music {
  background: var(--theme-red);
}
.option-item.music.show {
  right: 110px;
  opacity: 1;
}
</style>
